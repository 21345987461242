<template>
<div class="loading-class" v-loading="loading"
     element-loading-text="微信授权中..."
     element-loading-spinner="el-icon-loading"
     element-loading-background="rgba(255, 255, 255, 0.8)">
</div>
</template>

<script>
import { ElMessage } from 'element-plus'
import {
  getAccessTokenByCode,
  getUserInfoByIdToken,
  getIp,
  getByTel,
  getCompanyInfoByCode
} from '@/api/user.js'
import { forwardBID } from '@/api/forward.js'
import axios from 'axios'
export default {
  name: 'callback',
  data () {
    return {
      loading: true,
      code: this.$route.query.code,
      state: this.$route.query.state,
      userInfo: '',
      mobile: '',
      openid: '',
      unionid: ''
    }
  },
  mounted () {
    const vm = this
    console.log('code: ' + this.code + ' | state: ' + this.state)
    console.log(encodeURIComponent('https://yijingjia.com/loading'))
    const data = {
      code: this.code
    }
    getAccessTokenByCode(data).then(result => {
      if (result.code === 0) {
        console.log(JSON.stringify(result))
        // 获取idToken
        vm.openid = result.data.openid
        vm.unionId = result.data.unionid
        const data = {
          dateBackgroundUrl: '/bid/weixin/loginByUnionId',
          dateBackgroundRequestType: 'get',
          data: {
            unionId: result.data.unionid,
            loginByUnionId: 3
          }
        }
        forwardBID(data).then(result => {
          if (result.code === 0) {
            console.log(result.data)
            if (result.data) {
              vm.$store.dispatch('setIdToken', result.data)
            }
            getUserInfoByIdToken({
              idToken: result.data,
              openId: vm.openid,
              unionId: vm.unionId
            }).then(result => {
            }).catch(error => {
              ElMessage.error({ message: error, offset: 60 })
            })
            const data = {
              dateBackgroundUrl: '/bid/user/accountInfo', // 获取用户信息
              dateBackgroundRequestType: 'post',
              data: {
                idToken: result.data
              }
            }
            forwardBID(data).then(result => {
              if (result.code === 0) {
                const resultData = result.data
                vm.userInfo = resultData
                vm.mobile = resultData.mobile
                const params = {
                  tel: vm.mobile
                }
                getByTel(params).then(result => {
                  if (result.code === 0) {
                    if (result.data.enableStatus === 0) {
                      vm.saveUserInfoToSession()
                    } else {
                      window.parent.postMessage({
                        openid: vm.openid,
                        unionId: vm.unionId,
                        state: 'disable' // newUser: 新用户 loginSuccess: 登录成功 loginError: 登录失败 logining: 登录中 disable: 禁用
                      }, '*')
                    }
                  } else {
                    window.parent.postMessage({
                      openid: vm.openid,
                      unionId: vm.unionId,
                      state: 'loginError' // newUser: 新用户 loginSuccess: 登录成功 loginError: 登录失败 logining: 登录中 disable: 禁用
                    }, '*')
                  }
                }).catch(error => {
                  console.log(error)
                  window.parent.postMessage({
                    openid: vm.openid,
                    unionId: vm.unionId,
                    state: 'loginError' // newUser: 新用户 loginSuccess: 登录成功 loginError: 登录失败 logining: 登录中 disable: 禁用
                  }, '*')
                })
              } else {
                ElMessage.error({ message: result.msg, offset: 60 })
              }
            }).catch(error => {
              ElMessage.error({ message: error, offset: 60 })
            })
          } else {
            // 新用户弹出绑定页面 或者注册页面
            window.parent.postMessage({
              openid: vm.openid,
              unionId: vm.unionId,
              state: 'newUser' // newUser: 新用户 loginSuccess: 登录成功 loginError: 登录失败 logining: 登录中
            }, '*')
          }
        }).catch(error => {
          console.log(error)
          ElMessage.error('获取idToken失败')
        })
      } else {
        ElMessage.error({ message: result.msg, offset: 60 })
      }
    }).catch(error => {
      ElMessage.error({ message: error, offset: 60 })
    })
  },
  methods: {
    // 用户信息存放缓存中
    saveUserInfoToSession () {
      const that = this
      const resultData = that.userInfo
      that.$store.dispatch('setUserInfo', JSON.stringify(resultData))
      const requestArr = []
      requestArr.push(
        getIp().then(result => {
          if (result.code === 0) {
            const arr = result.data.split(',')
            resultData.ip = arr[0]
          } else {
            resultData.ip = '127.0.0.1'
          }
          that.$store.dispatch('setUserInfo', JSON.stringify(resultData))
        }).catch(error => {
          console.log(error)
        })
      )
      const params = {
        tel: that.mobile
      }
      requestArr.push(
        getByTel(params).then(result => {
          if (result.code === 0) {
            resultData.openId = that.openid
            result.data.openId = that.openid
            that.$store.dispatch('setVipData', JSON.stringify(result.data))
          } else {
            resultData.openId = that.openid
          }
          that.$store.dispatch('setUserInfo', JSON.stringify(resultData))
        }).catch(error => {
          console.log(error)
        })
      )
      requestArr.push(
        getCompanyInfoByCode({
          code: resultData.taxpayerNumber
        }).then(result => {
          const res = result?.data
          resultData.companyId = res?.data?.id
          that.$store.dispatch('setUserInfo', JSON.stringify(resultData))
        }).catch(error => {
          console.log(error)
        })
      )
      axios.all(requestArr).then(
        axios.spread((val1, val2) => {
          // val 是数组中每个接口返回的值 res.data
          window.parent.postMessage({
            state: 'loginSuccess' // newUser: 新用户 loginSuccess: 登录成功 loginError: 登录失败 logining: 登录中
          }, '*')
        })
      )
    }
  }
}
</script>

<style scoped>
.loading-class {
  width: 300px;
  height: 310px;
}
</style>
